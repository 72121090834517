import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { BrowserRouter } from 'react-router-dom';
import './App.css'
import NewsletterQuarterly from './NewsletterQuarterly';
function App() {

  return (
    <BrowserRouter>
        <div>
        <Route exact path="/" component={NewsletterQuarterly} /> 
            
        </div>
        
      </BrowserRouter>
    
     
      
  
  );
}

export default App;