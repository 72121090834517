import React, { Fragment, useEffect, useState, useContext } from "react";
import firebase from "./firebase";
import'./Newsletter.css';
import { MDBCol, MDBIcon, MDBContainer, MDBRow} from "mdbreact";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router";
import { motion } from 'framer-motion';
import { LoginPageNavbar } from "./LoginPageNavbar";

const useStyles = makeStyles({
  root: {
    
    maxWidth:'auto'
  },
});


export default function NewsletterQuarterly() {
  const classes = useStyles();
  const [schools, setschools] =useState([]);
  const [loading, setloading] = useState(true);
  const [years, setYears] = useState([])
  
   const [documentYear, setDocumentYear] = useState("")
   const [documentMonth, setDocumentMonth] = useState("all")
   const [currentUserType, setCurrentUserType] = useState("")



  

   
  
 

   useEffect(()=>{

    getyears()
 
  },[])
  
  function getyears(){
    const year = (new Date()).getFullYear()
    let items=[]
     setDocumentYear(year)
    for(let i=year; i>=2016; i--){
       let j= YearRange(i)
       let k = i+"-"+j
       items.push(k)
     
     setYears(items)

    }
  }
  const ref = firebase.firestore().collection("newsletter").orderBy('month','desc')
  
  function getSchools(){

    setloading(true);
    const items=[];
    ref.onSnapshot((querySnapshot)=>{
    
      querySnapshot.forEach((doc)=>{
        items.push(doc.data())
      });
      setschools(items);
      setloading(false);
    })
  }  
  
  useEffect(()=>{
    getSchools();
 
  },[]);

 
 
function YearRange(x){
  var e = parseInt(x) 
  var d = e+1;
  return d%100;
}
const history = useHistory()
  return (
     <>
      <LoginPageNavbar/>
    <div>

<div style={{ paddingTop: "120px" }}></div>

  
          <div className="d-flex justify-content-center" >
              
        <div className="d-flex p-2 col-example">
          <h1 style={{ fontWeight:"600", fontFamily:"Trebuchet MS"}}>Newsletter </h1>
        </div>
      </div>



          <MDBContainer  
    >
      <MDBRow>  <MDBCol md="3" >
      </MDBCol>
      
      <MDBCol md="3">  <label style={{ fontSize: "30px" }}> Select Month <span style={{color:"rgb(207,148,80)"}}>*</span> </label>
          <select value={documentMonth}  style = {{ width: "80%" }} className="form-control" onChange={(e)=> setDocumentMonth(e.target.value)}>
          <option value="all">Select Quarter</option>
            <option value="1">Quarter 1 (April-Jun)</option>
            <option value="2">Quarter 2 (July-September)</option>
            <option value="3">Quarter 3 (October-December)</option>
            <option value="4">Quarter 4 (January-March)</option>
          </select>
      
      </MDBCol>
      <MDBCol md='3'><label style={{ fontSize: "30px" }}> Select Year <span style={{color:"rgb(207,148,80)"}}>*</span> </label>
          <select value={documentYear}  style = {{ width: "80%" }} className="form-control" onChange={(e)=> setDocumentYear(e.target.value)}>
          {years.map((val,key)=>(
            <option key={key} value={val}>{val}</option>
          ))}
          </select></MDBCol> 
      <MDBCol md='3'></MDBCol>
      
      </MDBRow>
     
    </MDBContainer>

            <div style={{paddingTop:"80px"}}></div>

     <div className="img-grid">
         {schools.filter((val)=>{


           if(val.month ==documentMonth.toLowerCase()  && val.year== documentYear){return val}
           if(documentMonth=="all"){if(val.year==documentYear){  return val}}
         
      }).map((doc,key)=>(<>          
      
    <motion.div className="img-wrap" key={key} 
          layout
          whileHover={{ opacity: 1 }}s
          onClick={()=> window.open(doc.newsletter, "_blank")}
        >

          <motion.img src={doc.image} alt="uploaded pic"
          
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
          />
         
           
        </motion.div>
        

    </>))}
    </div>
    </div>
    </>
  );
}
